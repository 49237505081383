<template>
  <div>
    <ValidationObserver v-slot="context">
      <vx-card class="mb-base">
        <form @submit.prevent="submit(context)">
          <FormLayout>
            <FormLabel>
              <span>ชื่อ TVC *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="ชื่อ TVC" 
                rules="required">
                <vs-input
                  v-model="formData.title"
                  class="w-full"
                  name="title"
                  placeholder="กรอกชื่อ TVC"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รายละเอียด *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="รายละเอียด" 
                rules="required">
                <vs-textarea
                  :class="{ 'border border-danger': errors.length > 0 }"
                  v-model="formData.description"
                  label="รายละเอียด"
                  class="w-full"
                  rows="9"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>เลือกชนิดการอัพโหลด</span>
            </FormLabel>
            <FormContent>
              <ul>
                <li>
                  <vs-radio
                    v-model="formData.asset_type"
                    vs-name="radios1"
                    vs-value="youtube"
                  >VDO Youtube
                  </vs-radio>
                </li>
                <li>
                  <vs-radio
                    v-model="formData.asset_type"
                    vs-name="radios1"
                    vs-value="file"
                  >VDO File</vs-radio
                  >
                </li>
              </ul>
            </FormContent>
          </FormLayout>
          <FormLayout v-show="formData.asset_type === 'youtube'">
            <FormLabel>
              <span>URL Youtube *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${formData.asset_type === 'youtube' ? 'required|url' : ''}`"
                name="URL Youtube"
              >
                <vs-input
                  v-model="formData.asset_value"
                  class="w-full"
                  placeholder="URL Youtube"
                  name="asset_value"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Thumbnail(.jpg , .png , .gif ขนาด 1920 x 1080 px)</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="Thumbnail"
                rules="required|lh_mimes:jpg,png,gif|lh_image_resolution:1920,1080"
              >
                <ImageUpload 
                  :folder="`${baseFolder}/tvc/${formData.folder_name}/VDO Thumbnail`"
                  v-model="formData.thumbnail" 
                  selection-name="thumbnail"
                  :has-delete-button="canDelete(formData.thumbnail)"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout v-show="formData.asset_type === 'file'">
            <FormLabel>
              <span>Video (เฉพาะไฟล์ .mp4)</span><span class="text-danger"> *</span>
              <br><span class="font-bold">ขนาดไฟล์ vdo ไม่เกิน 300 MB</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${formData.asset_type === 'file' ? 'required|lh_size:300_MB' : 'lh_size:300_MB'}`"
                name="Video"
              >
                <VdoUpload 
                  :folder="`${baseFolder}/tvc/${formData.folder_name}/VDO`"
                  v-model="formData.asset" 
                  selection-name="assets"
                  :has-delete-button="canDelete(formData.asset)"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Link URL</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Link URL" 
                rules="url">
                <vs-input 
                  v-model="formData.link_url" 
                  class="w-full" 
                  name="link_url" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>วันที่จะเผยแพร่ *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="วันที่จะเผยแพร่"
                rules="required"
              >
                <datepicker
                  :format="useDatepicker.formatDate"
                  :highlighted="{
                    dates: [
                      // Highlight an array of dates
                      formData.published_at
                        ? new Date(formData.published_at)
                        : new Date(),
                    ],
                  }"
                  :disabled-dates="{
                    to: new Date(useDatepicker.moment().subtract(1, 'day'))
                  }"
                  v-model="formData.published_at"
                  placeholder="Select Date"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{
                    errors[0]
                  }}</span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <vs-divider />
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout class="mt-8">
            <FormLabel />
            <FormContent>
              <vs-button 
                class="mr-2" 
                @click="submit(context)"
              >
                {{ formData.id ? 'Update' : 'Submit' }}
              </vs-button>
            </FormContent>
          </FormLayout>
        </form>
      </vx-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import ImageUpload from '@/components/upload/ImageUpload'
import VdoUpload from '@/components/upload/VdoUpload'
import useDatepicker from '@/use/useDatepicker'
import useTvcUtil from '@/use/useTvcUtil'
import useCrud from '@/use/useCrud'
import env from '@/env'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'TvcForm',
  components: {
    VdoUpload,
    ImageUpload,
    Datepicker,
    ValidationObserver,
  },
  setup(props, ctx) {
    const { formData, fetchByRouteId } = useTvcUtil(ctx)
    const crudForm = useCrud(ctx, 'tvc')
    crudForm.routePrefix.value = 'lh-content-tvc'

    onMounted(() => {
      fetchByRouteId()
    })

    const submit = (ctx) => {
      crudForm.addItem(ctx, formData)
    }

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)

    const canDelete = (files) => {
      return !isEmpty(files)
    }

    return {
      ...crudForm,
      formData,
      submit,
      useDatepicker: useDatepicker(),
      baseFolder,
      canDelete
    }
  },
}
</script>
