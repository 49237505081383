var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('vx-card',{staticClass:"mb-base"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit(context)}}},[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อ TVC *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อ TVC","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title","placeholder":"กรอกชื่อ TVC"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("รายละเอียด *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"รายละเอียด","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",class:{ 'border border-danger': errors.length > 0 },attrs:{"label":"รายละเอียด","rows":"9"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("เลือกชนิดการอัพโหลด")])]),_c('FormContent',[_c('ul',[_c('li',[_c('vs-radio',{attrs:{"vs-name":"radios1","vs-value":"youtube"},model:{value:(_vm.formData.asset_type),callback:function ($$v) {_vm.$set(_vm.formData, "asset_type", $$v)},expression:"formData.asset_type"}},[_vm._v("VDO Youtube\n                ")])],1),_c('li',[_c('vs-radio',{attrs:{"vs-name":"radios1","vs-value":"file"},model:{value:(_vm.formData.asset_type),callback:function ($$v) {_vm.$set(_vm.formData, "asset_type", $$v)},expression:"formData.asset_type"}},[_vm._v("VDO File")])],1)])])],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.asset_type === 'youtube'),expression:"formData.asset_type === 'youtube'"}]},[_c('FormLabel',[_c('span',[_vm._v("URL Youtube *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"rules":("" + (_vm.formData.asset_type === 'youtube' ? 'required|url' : '')),"name":"URL Youtube"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"URL Youtube","name":"asset_value"},model:{value:(_vm.formData.asset_value),callback:function ($$v) {_vm.$set(_vm.formData, "asset_value", $$v)},expression:"formData.asset_value"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Thumbnail(.jpg , .png , .gif ขนาด 1920 x 1080 px)")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Thumbnail","rules":"required|lh_mimes:jpg,png,gif|lh_image_resolution:1920,1080"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/tvc/" + (_vm.formData.folder_name) + "/VDO Thumbnail"),"selection-name":"thumbnail","has-delete-button":_vm.canDelete(_vm.formData.thumbnail)},model:{value:(_vm.formData.thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "thumbnail", $$v)},expression:"formData.thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.asset_type === 'file'),expression:"formData.asset_type === 'file'"}]},[_c('FormLabel',[_c('span',[_vm._v("Video (เฉพาะไฟล์ .mp4)")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")]),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("ขนาดไฟล์ vdo ไม่เกิน 300 MB")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"rules":("" + (_vm.formData.asset_type === 'file' ? 'required|lh_size:300_MB' : 'lh_size:300_MB')),"name":"Video"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VdoUpload',{attrs:{"folder":(_vm.baseFolder + "/tvc/" + (_vm.formData.folder_name) + "/VDO"),"selection-name":"assets","has-delete-button":_vm.canDelete(_vm.formData.asset)},model:{value:(_vm.formData.asset),callback:function ($$v) {_vm.$set(_vm.formData, "asset", $$v)},expression:"formData.asset"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("Link URL")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"Link URL","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"link_url"},model:{value:(_vm.formData.link_url),callback:function ($$v) {_vm.$set(_vm.formData, "link_url", $$v)},expression:"formData.link_url"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("วันที่จะเผยแพร่ *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"วันที่จะเผยแพร่","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"format":_vm.useDatepicker.formatDate,"highlighted":{
                  dates: [
                    // Highlight an array of dates
                    _vm.formData.published_at
                      ? new Date(_vm.formData.published_at)
                      : new Date() ],
                },"disabled-dates":{
                  to: new Date(_vm.useDatepicker.moment().subtract(1, 'day'))
                },"placeholder":"Select Date"},model:{value:(_vm.formData.published_at),callback:function ($$v) {_vm.$set(_vm.formData, "published_at", $$v)},expression:"formData.published_at"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',{staticClass:"mt-8"},[_c('FormLabel'),_c('FormContent',[_c('vs-button',{staticClass:"mr-2",on:{"click":function($event){return _vm.submit(context)}}},[_vm._v("\n              "+_vm._s(_vm.formData.id ? 'Update' : 'Submit')+"\n            ")])],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }