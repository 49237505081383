import get from 'lodash/get'
import useCldUtil from '@/use/useCldUtil'
import { reactive } from '@vue/composition-api'

import { createFolder } from '@/helper/damUtils'
import { v4 as uuidv4 } from 'uuid'

/* eslint-disable-next-line */
export default (ctx) => {
  const formData = reactive({
    id: null,
    title: '',
    description: '',
    asset_type: 'youtube',
    asset_value: '',
    asset: [],
    link_url: '',
    thumbnail: [],
    published_at: null,
    folder_name: null,
    updated_at: null,
    created_by: null,
    updated_by: null
  })
  const { setRequest: setCldRequest } = useCldUtil(ctx)

  const prepareFormData = (result) => {
    formData.id = result.id
    formData.title = result.title
    formData.updated_at = result.updated_at
    formData.created_by = result.created_by
    formData.updated_by = result.updated_by
    formData.description = result.description
    formData.thumbnail_id = result.thumbnail_id
    formData.asset_id = result.asset_id
    formData.asset_type = result.asset_type
    formData.asset_value = result.asset_value

    formData.link_url = result.link_url || ''
    formData.published_at = result.published_at

    formData.folder_name = result.folder_name

    if (get(result, ['thumbnail', 'file_name'])) {
      formData.thumbnail = setCldRequest(result.thumbnail)
    }

    if (get(result, ['asset', 'file_name'])) {
      formData.asset = setCldRequest(result.asset)
    }
  }

  const fetchByRouteId = async () => {
    const revision_id = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['VDO', 'VDO Thumbnail']
    if (revision_id) {
      formData.revision_id = revision_id
      ctx.root.$vs.loading()
      await ctx.root.$store
        .dispatch('tvc/fetchOne', revision_id)
        .then((result) => {
          prepareFormData(result)
          if (!formData.folder_name) {
            const randomUuid = uuidv4()
            formData.folder_name = randomUuid
          }
          return createFolder(`tvc/${formData.folder_name}`, folders)
        })
        .then(() => ctx.root.$vs.loading.close())
        .catch((error) => {
          console.log(error)
        })
    } else {
      const randomUuid = uuidv4()
      ctx.root.$vs.loading()
      formData.folder_name = randomUuid
      await createFolder(`tvc/${randomUuid}`, folders)
      ctx.root.$vs.loading.close()
    }
  }

  return {
    formData,
    prepareFormData,
    fetchByRouteId,
  }
}
