<template>
  <div class="flex flex-col">
    <div class="flex flex-col mb-3">
      <cld-video
        v-for="(item, index) in assets"
        :key="index"
        :cloud-name="cldUpload.cldName.value"
        :public-id="item.public_id"
        width="400"
        lazy
        controls
      />
    </div>
    <div>
      <MediaWidgetInit
        :folder="folder"
        :multiple="false"
        :selection-name="selectionName"
        :has-delete-button="hasDeleteButton && assets && assets.length > 0"
        resource-type="video"
        @selected-value="cldUpload.onSelectedValue"
        @render="cldUpload.onRender"
        @on-delete="onDelete"
      >
        <slot>Select Video</slot>
      </MediaWidgetInit>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { CldVideo } from 'cloudinary-vue'
import MediaWidgetInit from '@/components/MediaWidgetInit'
import useCldUpload from '@/use/useCldUpload'
import { computed } from '@vue/composition-api'
import env from '@/env'

export default {
  name: 'VdoUpload',
  components: { MediaWidgetInit, CldVideo },
  props: {
    folder: {
      type: String,
      default: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/video`,
    },
    selectionName: {
      type: String,
      required: true,
    },
    hasDeleteButton: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const cldUpload = useCldUpload(ctx)
    const assets = computed(() => {
      return cldUpload.assets.value.length > 0 ? cldUpload.assets.value : ctx.attrs.value
    })

    const onDelete = () => {
      cldUpload.assets.value = []
      ctx.attrs.value = []
      ctx.emit('input', [])
    }

    return {
      cldUpload,
      assets,
      onDelete
    }
  },
}
</script>

<style scoped></style>
